@import '../../../../_bootstrap-variables';
$header-color: #fff;
$header-color-secondary: #bbb;
$header-color-hover: darken($header-color, 20%);

.header {
  height: $header-height;
  min-height: $header-height;
  max-height: $header-height;
  .nav-item{
    font-size: 1.1em;
  }
}

.navbar-toggler {
    font-size: 1em;
  &:focus {
    box-shadow: 0 0 0 0.1rem;
  }
}

.nav-link {
  color: $primary-dark;
}


.navbar-brand {
  overflow: hidden;
}

.jh-navbar .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-version {
  font-size: 10px;
  color: $header-color-secondary;
  padding: 0 0 0 10px;
}
