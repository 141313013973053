@import 'app/_bootstrap-variables.scss';

$sidebar-bg: #fff;


.sidebar-wrapper {
  background-color: $sidebar-bg;
  border-right: 2px solid $gray-400;
  height: 100vh;
  z-index: 1030;
}

.sidebar-container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.sidebar-full {
  padding-top: $header-height;
}

.sidebar-header {
  background-color: $sidebar-bg;
  height: $header-height;
  min-height: $header-height;
  max-height: $header-height;
}

.sidebar-header-fixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav-item {
  padding-left: .5em;
  font-size: 1.25rem;
  color: $white;
}

.active-sidebar-link {
  border-left: 3px solid $primary-dark;
  background: $nav-link-active-bg;
  color: $white !important;
  margin-left: -3px;
  margin-right: 3px;
}

.nav-link {
  display: flex;
  gap: .5em;
  align-items: center;
  color: $primary-dark;
}

.submenu-link {
  position: relative;
  margin-left: 16px;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    left: 0;
    background-color: $gray-200;
  }
}

.active-submenu-link {
  background: $nav-link-active-bg;
  color: $white !important;

  &:after {
    border-color: $primary-dark;
    background-color: $primary-dark;
  }
}

.sidebar-contents::-webkit-scrollbar {
  width: 10px;
}

.sidebar-contents::-webkit-scrollbar-thumb {
  background: $gray-400;
  border-radius: 3px;
}

.sidebar-contents::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
}



/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.4);
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 30px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;

  span {
    color: #fff;
    display: block;
    font-weight: 400;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
  }
}

/* ==========================================================================
Navbar styles
========================================================================== */
.navbar-brand {
  overflow: hidden;
}

.jh-navbar .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-version {
  font-size: 14px;
  color: $gray-500;

}

.brand-logo {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5em;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  .brand-icon {
    height: 35px;
    width: auto;
    display: inline-block;

    img {
      height: 45px;
    }
  }
}

.brand-title {
  font-size: 24px;
  color: $gray-700;
  transition: color 0.5s;
  text-transform: uppercase;
  font-weight: 400;

  &:hover {
    color: $gray-900;
    text-decoration: none;
  }
}
