/*
* Bootstrap overrides https://v4-alpha.getbootstrap.com/getting-started/options/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

/* LB Branding */
$primary-dark: #005475;
$primary-light: darken(#00a19e,1%);

$secondary-dark: #00c991;
$secondary-light: #c4f5cf;

$dark-gradient: linear-gradient(-45deg, $primary-dark 0%, $primary-light 75%);
$dark-gradient-hover: linear-gradient(-45deg, darken($primary-dark, 5%) 0%, darken($primary-light, 5%) 75%);
$light-gradient: linear-gradient(-45deg, $secondary-dark 0%, $secondary-light 75%);
$light-gradient-hover: linear-gradient(-45deg, darken($secondary-dark, 5%) 0%, darken($secondary-light, 5%) 75%);

$font-family-headings: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$body-color: $primary-dark !default;
$headings-color: $primary-dark !default;


$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #015376;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #E4572E;
$orange:  #f8765f;
$yellow:  #fdc23f;
$green:   #00c991;
$teal:    #c5f6cf;
$cyan:    #00a09f;

/* Custom theme */
$primary:       $primary-light;
$secondary:     $secondary-light;
$success:       $secondary-dark;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $primary-dark;
/* -------- */

$body-bg: #f5f6fe;
$nav-link-active-bg: $dark-gradient;
$navbar-light-color: $primary-dark;
$navbar-light-hover-color: $primary-dark;
$navbar-light-active-color: $primary-dark;

/* Custom constants */
$header-height: 70px;
$footer-height: 70px;
/* -------- */

.dropdown-item {
  color: $primary-dark;
  &:hover {
    background: $nav-link-active-bg;
    color: $white;
    path {
      fill: $white;
    }
    a {
      color: $white !important;
    }
  }
}

.neutral-dropdown-item{
  color: $primary-dark;
  background:transparent;
  border:none;
  box-shadow:none;
  &:hover {
    background: rgba(0,0,0,0.03);
    color:$primary-dark;
  }
  &:active{
    background:transparent;
  }
  &:focus{
    background:transparent;
  }
}

h1 {
  font-family: $font-family-headings;
  text-transform: uppercase;
  color: $primary-light;
}

h2,h3,h4,h5,h6 {
  font-family: $font-family-sans-serif;
  color: $primary-dark;
}

.dark-gradient {
  background: $dark-gradient;
  color: $white;
}

.light-gradient {
  background: $light-gradient;
  color: $white;
}

code {
  color: $primary-light;
  font-size: 1em;
  padding: 0.25em;
}

@import '~bootswatch/dist/zephyr/variables';

