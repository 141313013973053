@import 'app/_bootstrap-variables.scss';

.footer {
  height: $footer-height;
  min-height: $footer-height;
  margin: 0;
  padding: 0 1em;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 6px 2px rgba($dark, 0.05);
  z-index: 9;

   > p {
     flex-grow: 1;
   }
}
